import "@fmobile/fmobile-ui-kits/lib/css/index.css"
import "react-toastify/dist/ReactToastify.css"
import {clearLocalStorageOnNewBuild} from "./utils/funcs/clearLocalStorageOnNewBuild"

// dateRangePicker "react-date-range": "^1.4.0",
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file

clearLocalStorageOnNewBuild().then(() => {
  import("./renderApp")
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
