const LOCAL_STORAGE_VERSION = "22"
const localStorageName = "cra-local-storage-version"

export const clearLocalStorageOnNewBuild = () => {
  const newLocalStorageVersion = LOCAL_STORAGE_VERSION
  const olLocalStorageVersion = localStorage.getItem(localStorageName)
  if (newLocalStorageVersion) {
    if (newLocalStorageVersion !== olLocalStorageVersion) {
      console.log("localStorage has cleared")
      localStorage.clear()
      localStorage.setItem(localStorageName, newLocalStorageVersion)
    }
  }
  return Promise.resolve()
}
